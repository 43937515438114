import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
//console.log("dotenv1", process.env)
// import 'dotenv/config'
// require('dotenv').config()
// console.log("dotenv2", process.env)

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import App from './App.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faPlus, faDownload, faGear, faArrowLeft, faUser, faCircleCheck, faCircleExclamation, faCircleArrowLeft, faChevronLeft, faCirclePlus, faCircleMinus, faCircleXmark }
  from '@fortawesome/free-solid-svg-icons'

import { fal } from '@fortawesome/pro-light-svg-icons'

/* add icons to the library */
library.add(faGear, faPlus, faDownload, faArrowLeft, faUser, faChevronLeft, faCircleCheck, faCircleExclamation, faCircleArrowLeft,
  faCirclePlus, faCircleMinus, faCircleXmark, fal
  //  faHouse, faBed,
  //  faForkKnife, faUtensils, faLightbulb, , faCalculator, faChartPie,
  //  faFileInvoiceDollar, faMoneyCheckDollar, faPaperclip, faPhone,
  //  faScaleBalanced, faShop, faTags, faBellPlus,
  //  faAnchor, faAppleWhole, faBaby, faBabyCarriage, faBagShopping, faBaseballBatBall, faBath,
  //  faBeerMug,
  )

const app = createApp(App).use(router)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.use(ElementPlus)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')
